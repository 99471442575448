import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import Layout from "../components/layout"
import Hero from "../components/hero"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"
import TestimonialSection from "../components/testimonials-section"

const ServicesPage = () => (
    <Layout>
        <Seo 
          title="Web development and web design services" 
          description="Launch Lab offers full stack web development services. We are web designers, web developers and serverless application developers with offices in Sydney and Canberra."
          pathname="/services/"
        />

        <Hero
            h1="Web development services"  
            h2="We offer web design, web development and serverless application development services"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <motion.div className="below-hero"
                      initial={{ opacity: 0, x: 120 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">Websites</span>
                            <p>We design websites in Figma and develop them using either Gatsby.js or Webflow depending on requirements.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Software</span>
                            <p>The majority of our work is developing serverless web applications in Python and React for startups.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">ML &amp; more</span>
                            <p>We have machine learning skills in-house and develop other digital products too ... like chatbots.</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
          <div className="container">
            <div className="row">
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                <div className="small-h">Full stack web designers and developers</div>
                <h3>We create websites &amp; digital products for startups and companies in Sydney, Canberra and across Australia</h3>
                <p>
                    We work with our clients from idea to product design through to custom built end product. However, if you only require one of our services we're more than happy to fit into your workflow.
                </p>
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
              >
                <div className="services-wrapper top-row">
                  <div className="a-service">
                    <h4>
                      <Link to="/web-design/" className="btn-link">Web Design <ArrowRight /></Link>
                    </h4>
                    <p>We design user and search engine friendly websites and product UIs</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/web-development/" className="btn-link">Web Development <ArrowRight /></Link>
                    </h4>
                    <p>We develop websites, CMS' and serverless software</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/startup-web-application-development/" className="btn-link">Startup Developers <ArrowRight /></Link>
                    </h4>
                    <p>We help startup founders go from idea to end product</p>
                  </div>
                </div>
                <div className="services-wrapper">
                  <div className="a-service">
                    <h4>
                      <Link to="/startup-web-designers/" className="btn-link">Startup Web Design <ArrowRight /></Link>
                    </h4>
                    <p>We design startup websites and applications for early stage and established startups.</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/machine-learning-developers-sydney/" className="btn-link">Machine Learning <ArrowRight /></Link>
                    </h4>
                    <p>We use Python, PyTorch, Tensorflow, ML and data science to help data rich companies solve problems</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/aws-infrastructure/" className="btn-link">AWS <ArrowRight /></Link>
                    </h4>
                    <p>We assist startups with Amazon Web Services (AWS) serverless infrastructure setup and support.</p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad">
          <div className="container">
            <div className="row">
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                <div className="small-h">Tools</div>
                <h5>Some of the technology and tools we use to develop websites and applications</h5>
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
              >
                <div className="services-wrapper top-row">
                  <div className="a-service">
                    <h4>
                      <Link to="/gatsby-developer/" className="btn-link">Gatsby.js <ArrowRight /></Link>
                    </h4>
                    <p>We love Gatsby for developing fast loading and secure static websites</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/python-developer/" className="btn-link">Python Developers <ArrowRight /></Link>
                    </h4>
                    <p>Most of our backend development and ML work is written in Python</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/headless-cms/" className="btn-link">Headless CMS <ArrowRight /></Link>
                    </h4>
                    <p>We develop headless CMS' using either Contentful or Storyblok</p>
                  </div>
                </div>
                <div className="services-wrapper">
                  <div className="a-service">
                    <h4>
                      <Link to="/webflow-developer/" className="btn-link">Webflow Developers<ArrowRight /></Link>
                    </h4>
                    <p>Webflow is a powerful low-code and no-code platform for developing websites &amp; CMS'</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      <Link to="/react-developers-sydney-canberra/" className="btn-link">React Developers <ArrowRight /></Link>
                    </h4>
                    <p>Most of our frontend applications are written in React.js (Typescript)</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      Serverless Software
                    </h4>
                    <p>Most applications that we develop are serverless meaning scalability, lower costs &amp; better security</p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="row">
                <div className="well bg-purple">
                    <h4>AWS &amp; Webflow for startups partner</h4>
                    <p>Launch Lab is a <Link to="/webflow-developer/">Webflow for Startups</Link> partner and an <Link to="/aws-infrastructure/">AWS Activate</Link> partner. Contact us to find out how you can benefit from these startup programs.</p>
                </div>
            </div>
          </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="If you're looking for cost estimates, or just want to discuss your requirements, for a web design, web development or startup project we'd love to discuss how Launch Lab's experienced onshore Australian team can help."
        />
    </Layout>
)

export default ServicesPage